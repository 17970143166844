import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import s from './Header.module.scss';
import './Header.css';
import userImage from '../../img/user.svg';
import axios from 'axios';

import { useTranslation } from 'react-i18next';

const Header = ({ url, setIsAuth }) => {
  const cookieToken = document.cookie
    .split('; ')
    .find((row) => row.startsWith('authtoken='))
    ?.split('=')[1];
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState();

  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${url}/api/users/`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((res) => setUserName(res.data[0].name));
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsAuth(false);
    setIsModalOpen(false);
    document.cookie = `authtoken=; max-age=-1`;
    navigate('/');
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className={s.container}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={s.userImage}
        onClick={showModal}
      >
        <circle cx="12" cy="6" r="4" fill="#3B3B3B" />
        <path
          d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
          fill="#3B3B3B"
        />
      </svg>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className="userModal"
      >
        <p className="username">{userName}</p>
        <p onClick={handleOk} className="logout">
          {t('header.logout')}
        </p>
      </Modal>
    </div>
  );
};

export default Header;
