import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

//components imports
import { Button, Modal, Select } from 'antd';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import BotListItem from '../../components/BotListItem/BotListItem';

//css imports
import s from './BotList.module.scss';
import '../../components/Modals/CreationLoader/CreationLoader.scss';

//image imports
import loader from '../../img/loader.svg';

const BotList = ({ url, setIsAuth }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cookieToken = document.cookie
    .split('; ')
    .find((row) => row.startsWith('authtoken='))
    ?.split('=')[1];

  const botTypes = [
    { value: 'hr', label: 'Hr bot' },
    { value: 'consultant', label: 'Consultant' },
    { value: 'support', label: 'Support Specialist' },
  ];
  const [bots, setBots] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [botId, setBotId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleChange = (value) => {
    setBotId(value);
  };
  const handleOk = () => {
    if (botId) {
      setIsModalOpen(false);
      navigate(`/botsettings/${botId}`);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const editBot = (botType, uuid) => {
    navigate(`/botsettings/${uuid}`);
  };
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/bots/`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        response.data.map((item) => {
          return setBots((bots) => [
            ...bots,
            {
              title: item?.title,
              uuid: item?.uuid,
              botType: item?.botType,
              messages: item?.messages,
            },
          ]);
        });
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <div className={s.main_container}>
      <Sidebar url={url} setIsAuth={setIsAuth} />
      <div className={s.inner_container}>
        <Header url={url} setIsAuth={setIsAuth} />
        <div className={s.main}>
          <div className={s.mainHeader}>
            <h2 className={s.title}>{t('botList.title')}</h2>

            <button
              className={s.createBot}
              onClick={() => {
                navigate('/botsettings');
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M5 12H19M12 5V19"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {t('botList.createButton')}
            </button>
          </div>

          {bots &&
            bots.map((item) => (
              <BotListItem
                title={item.title}
                messageCount={item.messages}
                key={item.uuid}
                uuid={item.uuid}
                templateId={item.templateId}
                editBot={editBot}
              />
            ))}
        </div>
      </div>
      <Modal
        title=" "
        open={isLoading}
        footer={[null]}
        closable={false}
        className={s.loader}
      >
        <img src={loader} alt="loader" />
      </Modal>
    </div>
  );
};

export default BotList;
