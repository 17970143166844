import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import s from "./Auth.module.scss";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import logo from "../../img/logo.svg";
import mainImg from "../../img/authImg.png";
import { useTranslation } from "react-i18next";

const Auth = ({ url, setIsAuth }) => {
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onFinish = (values) => {
    //  console.log("Success:", values);
    axios
      .post(`${url}/api/auth/login/`, {
        email: values.username,
        password: values.password,
      })
      .then((response) => {
        document.cookie = `authtoken=${response.data.key}; max-age=864000`;
        setIsAuth(true);
      })
      .then(() => navigate("/botlist", { replace: true }))
      .catch((error) => {
        error.response?.data?.email
          ? setErrorMessage(error.response?.data?.email)
          : setErrorMessage(error.response?.data?.non_field_errors);
        console.log(error);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className={s.auth}>
      <div className={s.container}>
        <img src={logo} alt="logo" width={176} className={s.image} />
        <div className={s.content}>
          <p className={s.title}>{t("auth.welcome")}</p>
          <Form
            requiredMark={false}
            className={s.form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            {errorMessage && <div className={s.error}>{errorMessage}</div>}
            <p className={s.label}>E-mail</p>
            <Form.Item
              name="username"
              rules={[
                {
                  type: "email",
                  message: `${t("auth.incorrectEmail")}`,
                },
                { required: true, message: `${t("auth.enterEmail")}` },
              ]}
              colon={false}
            >
              <Input className={s.input} />
            </Form.Item>
            <p className={s.label}>{t("auth.password")}</p>
            <Form.Item name="password" rules={[{ required: true, message: `${t("auth.enterPass")}` }]} colon={false}>
              <Input.Password className={s.input} />
            </Form.Item>
            <div className={s.buttonContainer}>
              <Form.Item className={s.button}>
                <Button htmlType="submit">{t("auth.login")}</Button>
              </Form.Item>
              <Form.Item className={s.whiteButton}>
                <Button onClick={() => navigate("/registration")}>{t("auth.create")}</Button>
              </Form.Item>
            </div>
            <p className={s.info}>
              {t("auth.confirm")}
              <br />
              <Link to="#">{t("auth.confirmLink")}</Link>
            </p>
          </Form>
        </div>
        <p className={s.info}>{t("auth.rights")}</p>
      </div>
      <div className={s.imgContainer}>
        <img src={mainImg} alt="" className={s.mainImg} />
      </div>
    </div>
  );
};

export default Auth;
