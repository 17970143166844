import React from 'react';
import { Link } from 'react-router-dom';
import { Select, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './AvatarModal.scss';
import noteIcon from '../../../img/note.svg';
import ToggleSlider from '../../ToggleSlider/ToggleSlider';
import { convertBase64 } from '../../../utils/convertBase64';
import s from '../../../pages/BotSettings/BotSettings.module.scss';

const AvatarModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  widgetData,
  widgetCode,
  onFinish,
  onUpdate,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('avatarModal.title')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[null]}
      closable={false}
      className="tokenModal"
    >
      <button className="closeButton" onClick={handleCancel}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.4143 12.0002L18.7072 6.70725C19.0982 6.31625 19.0982 5.68425 18.7072 5.29325C18.3162 4.90225 17.6842 4.90225 17.2933 5.29325L12.0002 10.5862L6.70725 5.29325C6.31625 4.90225 5.68425 4.90225 5.29325 5.29325C4.90225 5.68425 4.90225 6.31625 5.29325 6.70725L10.5862 12.0002L5.29325 17.2933C4.90225 17.6842 4.90225 18.3162 5.29325 18.7072C5.48825 18.9022 5.74425 19.0002 6.00025 19.0002C6.25625 19.0002 6.51225 18.9022 6.70725 18.7072L12.0002 13.4143L17.2933 18.7072C17.4882 18.9022 17.7443 19.0002 18.0002 19.0002C18.2562 19.0002 18.5122 18.9022 18.7072 18.7072C19.0982 18.3162 19.0982 17.6842 18.7072 17.2933L13.4143 12.0002Z"
            fill="#3B3B3B"
            fill-opacity="0.4"
          />
        </svg>
      </button>
      <div className="sliderContainer">
        <p>{t('avatarModal.activate')}</p>
        <ToggleSlider
          id="activate-avatar"
          value={widgetData.is_enabled}
          default={widgetData.is_enabled}
          toggleValue={(e) =>
            onUpdate({ ...widgetData, is_enabled: !widgetData.is_enabled })
          }
        />
      </div>
      <div style={{ display: 'flex', gap: '20px' }}>
        {widgetData.source_url && (
          <img src={widgetData.source_url} style={{ maxWidth: '100px' }} />
        )}
        <button className="blueButton" disabled={widgetData.kind === 'CLIP'}>
          <label>
            {t('avatarModal.photo')}
            <Input
              type="file"
              className="hidden"
              accept=".jpg,.png"
              disabled={widgetData.kind === 'CLIP'}
              onChange={(e) => {
                convertBase64(e.target.files[0]).then((fileItem) => {
                  onUpdate({ ...widgetData, picture: fileItem.file });
                });
              }}
            />
          </label>
        </button>
      </div>

      <label className="labelText" style={{ marginTop: '16px' }}>
        {t('avatarModal.photoUrl')}
      </label>
      <Input
        type="text"
        placeholder={t('avatarModal.link')}
        className="tokenInput"
        value={widgetData.photo_url}
        disabled={widgetData.kind === 'CLIP'}
        onChange={(e) => onUpdate({ ...widgetData, photo_url: e.target.value })}
      />

      <div className="info">
        <p>{t('avatarModal.formats')}</p>
        <p>{t('avatarModal.size')}</p>
      </div>

      <Select
        className={s.input}
        name="voice"
        id="voice"
        size="large"
        value={widgetData.voice}
        style={{ border: 'none', marginBottom: '16px', marginTop: '16px' }}
        onChange={(e) => onUpdate({ ...widgetData, voice: e })}
      >
        <Select.Option value="microsoft:en-US-AndrewNeural">
          {t('avatarModal.eMan')}
        </Select.Option>
        <Select.Option value="microsoft:en-US-AvaNeural">
          {t('avatarModal.eWoman')}
        </Select.Option>
        <Select.Option value="microsoft:ar-SA-HamedNeural">
          {t('avatarModal.aMan')}
        </Select.Option>
        <Select.Option value="microsoft:ar-SA-ZariyahNeural">
          {t('avatarModal.aWoman')}
        </Select.Option>
        <Select.Option value="microsoft:ru-RU-DmitryNeural">
          {t('avatarModal.rMan')}
        </Select.Option>
        <Select.Option value="microsoft:ru-RU-SvetlanaNeural">
          {t('avatarModal.rWoman')}
        </Select.Option>
      </Select>

      <div className="integration">
        <div className="header">
          <p>{t('avatarModal.code')}</p>

          {widgetData.id ? (
            <Link
              target="_blank"
              className="demolink"
              to={
                window.location.protocol +
                '//' +
                window.location.hostname +
                '/widgets/' +
                widgetCode +
                '/?avatar=1'
              }
            >
              {t('avatarModal.demo')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <g
                  stroke="#3B3B3B"
                  stroke-linecap="round"
                  stroke-opacity=".2"
                  stroke-width="1.5"
                  clip-path="url(#a)"
                >
                  <path
                    stroke-linejoin="round"
                    d="m10.833 9.167 7.5-7.5m0 0H13.88m4.453 0V6.12"
                  />
                  <path d="M18.333 10c0 3.929 0 5.893-1.22 7.113-1.22 1.22-3.184 1.22-7.113 1.22-3.928 0-5.892 0-7.113-1.22-1.22-1.22-1.22-3.184-1.22-7.113 0-3.928 0-5.892 1.22-7.113 1.22-1.22 3.185-1.22 7.113-1.22" />
                </g>
                <defs>
                  <clipPath id="a">
                    <rect width="20" height="20" fill="#fff" rx="5" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          ) : (
            <Link
              target="_blank"
              className="demolink"
              to={
                window.location.protocol +
                '//' +
                window.location.hostname +
                '/widgets/' +
                widgetCode +
                '/?avatar=1'
              }
              onClick={(event) => event.preventDefault()}
            >
              {t('avatarModal.demo')}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <g
                  stroke="#3B3B3B"
                  stroke-linecap="round"
                  stroke-opacity=".2"
                  stroke-width="1.5"
                  clip-path="url(#a)"
                >
                  <path
                    stroke-linejoin="round"
                    d="m10.833 9.167 7.5-7.5m0 0H13.88m4.453 0V6.12"
                  />
                  <path d="M18.333 10c0 3.929 0 5.893-1.22 7.113-1.22 1.22-3.184 1.22-7.113 1.22-3.928 0-5.892 0-7.113-1.22-1.22-1.22-1.22-3.184-1.22-7.113 0-3.928 0-5.892 1.22-7.113 1.22-1.22 3.185-1.22 7.113-1.22" />
                </g>
                <defs>
                  <clipPath id="a">
                    <rect width="20" height="20" fill="#fff" rx="5" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          )}
        </div>
        <div className="link">
          <p>
            {window.location.protocol +
              '//' +
              window.location.hostname +
              '/widgets/' +
              widgetCode +
              '/?avatar=1'}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <path
              fill="#3B3B3B"
              fill-opacity=".2"
              fill-rule="evenodd"
              d="M15 1.25h-4.056c-1.838 0-3.294 0-4.433.153-1.172.158-2.121.49-2.87 1.238-.748.749-1.08 1.698-1.238 2.87-.153 1.14-.153 2.595-.153 4.433V16a3.751 3.751 0 0 0 3.166 3.705c.137.764.402 1.416.932 1.947.602.602 1.36.86 2.26.982.867.116 1.97.116 3.337.116h3.11c1.367 0 2.47 0 3.337-.116.9-.122 1.658-.38 2.26-.982.602-.602.86-1.36.982-2.26.116-.867.116-1.97.116-3.337v-5.11c0-1.367 0-2.47-.116-3.337-.122-.9-.38-1.658-.982-2.26-.531-.53-1.183-.795-1.947-.932A3.75 3.75 0 0 0 15 1.25Zm2.13 3.021A2.25 2.25 0 0 0 15 2.75h-4c-1.907 0-3.261.002-4.29.14-1.005.135-1.585.389-2.008.812-.423.423-.677 1.003-.812 2.009-.138 1.028-.14 2.382-.14 4.289v6a2.25 2.25 0 0 0 1.521 2.13c-.021-.61-.021-1.3-.021-2.075v-5.11c0-1.367 0-2.47.117-3.337.12-.9.38-1.658.981-2.26.602-.602 1.36-.86 2.26-.981.867-.117 1.97-.117 3.337-.117h3.11c.775 0 1.465 0 2.075.021ZM7.408 6.41c.277-.277.665-.457 1.4-.556.754-.101 1.756-.103 3.191-.103h3c1.435 0 2.436.002 3.192.103.734.099 1.122.28 1.399.556.277.277.457.665.556 1.4.101.754.103 1.756.103 3.191v5c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399-.277.277-.665.457-1.4.556-.755.101-1.756.103-3.191.103h-3c-1.435 0-2.437-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191v-5c0-1.435.002-2.437.103-3.192.099-.734.28-1.122.556-1.399Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div className="buttonContainer">
        <button className="whiteButton" onClick={handleCancel}>
          {t('avatarModal.back')}
        </button>
        <button className="blueButton" onClick={onFinish}>
          {t('avatarModal.save')}
        </button>
      </div>
    </Modal>
  );
};

export default AvatarModal;
