import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const lng = window.location.hostname.split(".").reverse()[0];
console.log(lng);
i18n.use(Backend).use(initReactI18next).init({
  lng: lng,
  debug: true,
  fallbackLng: "en",
});

export default i18n;
