import React from 'react';
import { Button, Modal } from 'antd';
import './DeleteBot.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DeleteBot = ({
  showModal,
  isModalOpen,
  handleCancel,
  botId,
  cookieToken,
  url,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deleteBotFunc = () => {
    showModal('creationLoader', 'deleteLoader');
    axios
      .delete(`${url}/api/bots/${botId}`, {
        headers: {
          Authorization: `Token ${cookieToken}`,
        },
      })
      .then((response) => {
        navigate('/botlist');
      });
  };
  return (
    <div>
      <Modal
        title={t('deleteBotModal.title')}
        open={isModalOpen}
        onOk={deleteBotFunc}
        onCancel={handleCancel}
        footer={[null]}
        closable={false}
        className="deleteBot"
      >
        <p>{t('deleteBotModal.text')}</p>

        <Button className="continue" onClick={handleCancel}>
          {t('deleteBotModal.continue')}
        </Button>
        <Button className="delete" onClick={deleteBotFunc}>
          {t('deleteBotModal.delete')}
        </Button>
      </Modal>
    </div>
  );
};

export default DeleteBot;
