import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import s from './Registration.module.scss';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../../img/logo.svg';
import RegistrationSuccess from '../../components/Modals/RegistrationSuccess/RegistrationSuccess';
import mainImg from '../../img/authImg.png';

const Registration = ({ url }) => {
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [IsLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    axios
      .post(`${url}/api/registration/`, {
        email: values.email,
        password1: values.password,
        password2: values.confirm,
        name: values.name,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 204) {
          setIsLoginModalOpen(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(
          Object.values(error.response.data).map((item) => item[0])
        );
      });
  };

  return (
    <div className={s.registration}>
      <div className={s.container}>
        <img src={logo} alt="logo" width={176} className={s.image} />
        <div className={s.content}>
          <p className={s.title}>{t('registration.title')}</p>
          <Form
            requiredMark={false}
            form={form}
            onFinish={onFinish}
            scrollToFirstError
            className={s.form}
          >
            {errorMessage &&
              errorMessage.map((item) => (
                <div key={item} className={s.error}>
                  {item}
                </div>
              ))}
            <p className={s.label}>{t('registration.name')}</p>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t('registration.enterName')}`,
                  whitespace: true,
                },
              ]}
              colon={false}
              className={s.inputContainer}
            >
              <Input className={s.input} />
            </Form.Item>
            <p className={s.label}>E-mail</p>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: `${t('registration.incorrectEmail')}`,
                },
                {
                  required: true,
                  message: `${t('registration.enterEmail')}`,
                },
              ]}
              colon={false}
              className={s.inputContainer}
            >
              <Input className={s.input} />
            </Form.Item>
            <p className={s.label}>{t('registration.password')}</p>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: `${t('registration.enterPass')}`,
                },
                {
                  min: 8,
                  message: `${t('registration.shortPass')}`,
                },
              ]}
              hasFeedback
              colon={false}
              className={s.inputContainer}
            >
              <Input.Password className={s.input} />
            </Form.Item>
            <p className={s.label}>{t('registration.passwordConfirm')}</p>
            <Form.Item
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: `${t('registration.confirmPassword')}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(`${t('registration.errorPass')}`)
                    );
                  },
                }),
              ]}
              colon={false}
              className={s.inputContainer}
            >
              <Input.Password className={s.input} />
            </Form.Item>
            <div className={s.buttonContainer}>
              <Form.Item className={s.button}>
                <Button type="primary" htmlType="submit">
                  {t('registration.createButton')}
                </Button>
              </Form.Item>
              <Form.Item className={s.whiteButton}>
                <Button
                  type="primary"
                  onClick={() => navigate('/authorization')}
                >
                  {t('registration.loginButton')}
                </Button>
              </Form.Item>
            </div>
            <p className={s.info}>
              {t('registration.confirm')}
              <br />
              <Link to="#">{t('registration.confirmLink')}</Link>
            </p>
          </Form>
        </div>
        <p className={s.info}>{t('registration.rights')}</p>
        <RegistrationSuccess
          IsLoginModalOpen={IsLoginModalOpen}
          setIsLoginModalOpen={setIsLoginModalOpen}
        />
      </div>
      <div className={s.imgContainer}>
        <img src={mainImg} alt="" className={s.mainImg} />
      </div>
    </div>
  );
};

export default Registration;
