import React from 'react';
import { Modal } from 'antd';
import './CreationLoader.scss';
import loader from '../../../img/loader.svg';

const CreationLoader = ({ isModalOpen, deleteLoader }) => {
  return (
    <div>
      {deleteLoader ? (
        <Modal
          title="Your bot is being deleted"
          open={isModalOpen}
          footer={[null]}
          closable={false}
          className="creationLoader"
        >
          <img src={loader} alt="loader" />
        </Modal>
      ) : (
        <Modal
          title="Your bot is being created"
          open={isModalOpen}
          footer={[null]}
          closable={false}
          className="creationLoader"
        >
          <p>
            Please wait while we create your bot. It won't take more than 2
            minutes.
          </p>
          <img src={loader} alt="" />
        </Modal>
      )}
    </div>
  );
};

export default CreationLoader;
