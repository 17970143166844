import React from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import './CancelChange.scss';
import { useNavigate } from 'react-router-dom';

const CancelChange = ({ isModalOpen, onFinish, setIsModalOpen, url }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cancelChangeFunc = () => {
    setIsModalOpen(false);
  };

  const saveFunc = () => {
    onFinish();
    setIsModalOpen(false);
    setTimeout(() => {
      navigate(url);
    }, 100);
  };
  return (
    <div>
      <Modal
        title={t('cancelChangeModal.title')}
        open={isModalOpen}
        onOk={cancelChangeFunc}
        onCancel={cancelChangeFunc}
        footer={[null]}
        closable={false}
        className="cancelChange"
      >
        <Button className="continue" onClick={saveFunc}>
          {t('cancelChangeModal.save')}
        </Button>
        <Button className="cancel" onClick={cancelChangeFunc}>
          {t('cancelChangeModal.continue')}
        </Button>
      </Modal>
    </div>
  );
};

export default CancelChange;
