import React from "react";
import "./ToggleSlider.css";

export default function ToggleSlider({ value, toggleValue, id }) {
  return (
    <div className="toggleSlider">
      <input type="checkbox" id={id} checked={value} onChange={toggleValue} name={id} />
      <label for={id}>Toggle</label>
    </div>
  );
}
