import React from 'react';
import { Button, Modal } from 'antd';
import './CreationError.scss';
import errorIcon from '../../../img/error.svg';

const CreationError = ({ isModalOpen, handleCancel, errorText }) => {
  return (
    <div>
      <Modal
        title="Error when creating a bot"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[null]}
        closable={false}
        className="сreationError"
      >
        <p>
          An error occurred during the creation of the bot. Please try again.
        </p>
        <ul className="error">
          {Array.isArray(errorText) ? (
            errorText.map((item) => (
              <li>
                <img src={errorIcon} alt="errorIcon" />
                <p>{item}</p>
              </li>
            ))
          ) : (
            <li>
              <img src={errorIcon} alt="errorIcon" />
              <p>Fill in all the fields.</p>
            </li>
          )}
        </ul>
        <Button className="continue" onClick={handleCancel}>
          Back to editing
        </Button>
        <Button className="help">Need help</Button>
      </Modal>
    </div>
  );
};

export default CreationError;
