import { useState, useEffect } from 'react';
import './App.css';
import Auth from './pages/Auth/Auth';
import BotList from './pages/BotList/BotList.jsx';
import BotSettings from './pages/BotSettings/BotSettings';
import Registration from './pages/Registration/Registration';
import { Routes, Route } from 'react-router-dom';

function App() {
  const url = window.location.origin;
   // const url = 'https://app.dev.cleverbots.ae';

  const [isAuth, setIsAuth] = useState(false);
  let cookieToken = document.cookie
    .split('; ')
    .find((row) => row.startsWith('authtoken='))
    ?.split('=')[1];
  useEffect(() => {
    if (cookieToken) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
  }, [cookieToken]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Auth url={url} setIsAuth={setIsAuth} />} />
        <Route
          path="/authorization"
          element={<Auth url={url} setIsAuth={setIsAuth} />}
        />
        <Route path="/registration" element={<Registration url={url} />} />
        <Route
          path="/botlist"
          element={isAuth ? <BotList url={url} setIsAuth={setIsAuth} /> : null}
        />
        <Route
          path="/botsettings"
          element={
            isAuth ? <BotSettings url={url} setIsAuth={setIsAuth} /> : null
          }
        />
        <Route
          path="/botsettings/:botId"
          element={
            isAuth ? <BotSettings url={url} setIsAuth={setIsAuth} /> : null
          }
        />
      </Routes>
    </div>
  );
}

export default App;
