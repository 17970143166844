import React from 'react';
import { Form, Slider } from 'antd';
import './CustomSlider.css';

const CustomSlider = (props) => {
  return (
    <>
      <p className="label">{props.label}</p>

      <Form.Item name={props.name} colon={false}>
        <Slider
          min={props.min}
          max={props.max}
          step={props.step}
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          dots={true}
          className="slider"
        />
      </Form.Item>
      <div className="sliderMarks">
        <span className="span">{props.minMark}</span>
        <span className="span">{props.maxMark}</span>
      </div>
    </>
  );
};

export default CustomSlider;
