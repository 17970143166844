import React from "react";
import { Button, Modal } from "antd";
import "./CreationSuccess.scss";
import botImage from "../../../img/bot-img.svg";
import { useNavigate } from "react-router-dom";

const CreationSuccess = ({ isModalOpen, setIsModalOpen, name, botName }) => {
  const navigate = useNavigate();
  const cancelChangeFunc = () => {
    navigate("/botlist");
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        title={`🎉 Meet, ${name}!`}
        open={isModalOpen}
        onCancel={cancelChangeFunc}
        footer={[null]}
        closable={false}
        className="creationSuccess"
      >
        <img src={botImage} alt="" />
        <p>
          Your bot is ready! <br />
          You can already say hello to him here:
        </p>
        <Button
          className="chatWithBot"
          onClick={() => window.open(`https://t.me/${botName}`)}
        >
          Chat with the bot
        </Button>
      </Modal>
    </div>
  );
};

export default CreationSuccess;
