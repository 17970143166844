import React from 'react';
import { Button, Modal } from 'antd';
import './RegistrationSuccess.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RegistrationSuccess = ({ IsLoginModalOpen, setIsLoginModalOpen }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleCancel = () => {
    setIsLoginModalOpen(false);
  };
  return (
    <div>
      <Modal
        title={t('registrationModal.title')}
        open={IsLoginModalOpen}
        onCancel={handleCancel}
        footer={[null]}
        closable={false}
        className="registrationSuccess"
      >
        <p>{t('registrationModal.text')}</p>
        <Button className="login" onClick={() => navigate('/authorization')}>
          {t('registrationModal.login')}
        </Button>
      </Modal>
    </div>
  );
};

export default RegistrationSuccess;
